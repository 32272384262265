export const users = {
  estado: [
    {
      label: 'Activo',
      value: 1,
      variant: 'successs',
    },
    {
      label: 'Pendiente',
      value: 2,
      variant: 'warning',
    },
    {
      label: 'Inactivo',
      value: 3,
      variant: 'secondary',
    },
  ],
}
